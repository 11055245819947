import {Alert, AlertTitle, Box, Snackbar} from "@mui/material";
import {Reward} from "../../utils/model/Reward";
import {useTranslation} from "react-i18next";

interface Props {
    reward: Reward | null,
    onClose(): void
}

export default function FeedbackBanner({reward, onClose}: Props) {
    const {t} = useTranslation();

    return (
        <Snackbar
            sx={{ mt: { xs: '70px', sm: '50px' } }}
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={5000}
            onClose={onClose}
        >
            <Alert
                severity={"success"}
                variant="filled"
                onClose={onClose}
                sx={{ cursor: 'pointer' }}
            >
                <Box>
                    <AlertTitle sx={{ fontWeight: 'bold' }}>
                        {t('Thanks for contributing')}
                    </AlertTitle>
                    {reward &&
                        <>
                            {t('+ {{count}} points', {count: reward.points})}
                        </>
                    }
                </Box>
            </Alert>
        </Snackbar>
    )
}