import { Location } from "./Location";
import {FuelCollection} from "./FuelCollection";
import { FuelType } from "./FuelType";
import { Fuel } from "./Fuel";

export interface Station {
    id: string;
    displayName: string;
    brand: string;
    formattedAddress: string;
    location: Location;
    providerInfo:ProviderInfo;
    hasTCSMastercardCashback: boolean;
    fuelCollection: FuelCollection;
    isDeleted: boolean;
}

export interface ProviderInfo {
    id: string
    source: string
}

export enum Brand {
    AVIA = "AVIA",
    AGROLA = "AGROLA",
    BP = "BP",
    ENI = "ENI",
    MIGROL = "MIGROL",
    SHELL = "SHELL",
    TAMOIL = "TAMOIL",
    COOP = "COOP",
    SOCAR = "SOCAR",
    PICCADILLY = "PICCADILLY",
    JUBIN = "JUBIN",
    RUEDIRUSSEL = "RUEDIRUSSEL",
    MINIPRIX = "MINIPRIX",
    COMBUSTIA = "COMBUSTIA",
    OIL = "OIL",
    MIDLAND = "MIDLAND",
    OELTRANS = "OELTRANS",
    FREDYS = "FREDYS",
    REDFOX = "REDFOX",
    SIMOND = "SIMOND",
    SPURT = "SPURT",
    ETZELPARK = "ETZELPARK",
    UNDEFINED = "UNDEFINED",
    MONOSUISSE = "MONOSUISSE",
    ECOSTOP = "ECOSTOP",
    LAPOSTE = "LAPOSTE",
    VOEGTLINMEYER = "VOEGTLINMEYER",
    FAUCHERRE = "FAUCHERRE"
}

export const BrandValues = Object.values(Brand)

export function getFuelFromStation(station:Station, fuelType:FuelType): Fuel | undefined {
    return !station.fuelCollection[fuelType as keyof FuelCollection]?.isDeleted
        ? station.fuelCollection[fuelType as keyof FuelCollection]
        : undefined;
}

export function isStation(object: unknown): object is Station {
    return Object.prototype.hasOwnProperty.call(object, "displayName")
        && Object.prototype.hasOwnProperty.call(object, "brand")
        && Object.prototype.hasOwnProperty.call(object, "formattedAddress")
        && Object.prototype.hasOwnProperty.call(object, "location")
        && Object.prototype.hasOwnProperty.call(object, "providerInfo")
        && Object.prototype.hasOwnProperty.call(object, "hasTCSMastercardCashback")
        && Object.prototype.hasOwnProperty.call(object, "fuelCollection");
}