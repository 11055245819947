import {Button, Stack} from "@mui/material";
import {tcsBlueGray1} from "../../mui/Theme";
import React from "react";
import {useRouter} from "../../context/RouterContext";
import {useProfile} from "../../context/ProfileContext";
import {UserType} from "../../utils/model/UserType";

export default function AdminDashboard() {
    const {navigate} = useRouter();
    const {profile} = useProfile();

    return (
        <>
            <Stack direction='column' height={'100%'}>
                <Stack sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                    <h2>Admin Dashboard</h2>
                </Stack>
                <Stack sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: '5px',
                    background: tcsBlueGray1,
                    padding: 2,
                    margin: 2
                }}>
                    <h3>Users</h3>
                    <Stack sx={{
                        flexDirection: "row",
                        gap: 1
                    }}>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => navigate("/admin/user/find")}
                        >
                            {"Find an user"}
                        </Button>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => navigate("/admin/user/banned")}
                        >
                            {"Banned users"}
                        </Button>
                    </Stack>
                </Stack>
                <Stack sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: '5px',
                    background: tcsBlueGray1,
                    padding: 2,
                    margin: 2
                }}>
                    <h3>Stations</h3>
                    <Stack sx={{
                        flexDirection: "row",
                        gap: 1
                    }}>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => navigate("/admin/station/find")}
                        >
                            {"Find a station"}
                        </Button>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => navigate("/admin/station/create")}
                        >
                            {"Create station"}
                        </Button>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => navigate("/admin/station/removed")}
                        >
                            {"Fuels and stations removed"}
                        </Button>
                    </Stack>
                </Stack>
                {profile?.type === UserType.ADMIN &&
                    <Stack sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: '5px',
                        background: tcsBlueGray1,
                        padding: 2,
                        margin: 2
                    }}>
                        <h3>Contests</h3>
                        <Stack sx={{
                            flexDirection: "row",
                            gap: 1
                        }}>
                            <Button
                                variant="contained"
                                type="button"
                                onClick={() => navigate("/admin/contest/active")}
                            >
                                {"Active contests"}
                            </Button>
                            <Button
                                disabled
                                variant="contained"
                                type="button"
                                onClick={() => navigate("/admin/contest/create")}
                            >
                                {"Create contest"}
                            </Button>
                        </Stack>
                    </Stack>
                }
            </Stack>
        </>
    )
}